import { NpPermission } from "./np-permission";

export abstract class NpPermissionUtils {

    static canAccess(permission: NpPermission | null | undefined, isLoggedIn: boolean, authorities: string[] | null): boolean {

        if (NpPermissionUtils.isEmpty(permission) == true) {
            return true;
        }

        // 인증확인
        if (permission!.authorized === true && isLoggedIn !== true) {
            return false;
        }

        // 거부 권한 체크
        if (permission!.deny && permission!.deny.length > 0 && NpPermissionUtils.hasAnyRole(permission!.deny, authorities) === true) {
            return false;
        }

        // 허용권한 체크
        if (permission!.allow && permission!.allow.length > 0 && NpPermissionUtils.hasAnyRole(permission!.allow, authorities) === false) {
            return false;
        }

        return true;
    }

    static isEmpty(permission: NpPermission | null | undefined): boolean {

        if (permission === undefined || permission == null) {
            return true;
        }

        if (permission.allow && permission.allow.length !== 0) {
            return false;
        }

        if (permission.deny && permission.deny.length !== 0) {
            return false;
        }

        if (permission.authorized === true) {
            return false;
        }

        return true;

    }

    /**
     * 
     * @param roles 검증하고자 하는 권한
     * @param uas 사용자의 현재 권한
     * @returns 
     */
    static hasAnyRole(roles: string[], uas: string[] | null): boolean {

        if (!roles || roles.length < 1) {
            throw new Error("roles is empty.");
        }

        if (!uas || uas.length < 1) {
            return false;
        }

        // 타켓 롤을 유저가 가지고 있는지 검증
        return roles.some(role => {
            let targetRole = role.toUpperCase();
            return uas.some(ua => targetRole === ua.toUpperCase())
        });
    }

    static convertStrignArray(obj: string | Array<string> | null | undefined): Array<string> {
        if (obj === undefined) return [];
        if (obj === null) return [];
        if (Array.isArray(obj) && obj.length > 0) return obj as Array<string>;
        if (typeof obj === 'string' && obj.length > 0) return [obj as string];
        return [];
    }

}