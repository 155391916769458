import { Injectable } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { NpBreadCrumb } from "./np-breadcrumb";

@Injectable()
export class NpBreadcrumbService {

    private _subscriptions: Subscription[] = [];

    private _bcRoot: NpBreadCrumb[] = [];
    private _bcApp: NpBreadCrumb[] = [];
    private _bcSba: NpBreadCrumb[] = [];
    private _bcOneOff!: NpBreadCrumb | null;

    protected _dataBs: BehaviorSubject<NpBreadCrumb[]> = new BehaviorSubject<NpBreadCrumb[]>([]);

    public get dataObs(): Observable<any> {
        return this._dataBs.asObservable();
    }

    constructor(private router: Router) {

        let routerSubs = router.events.subscribe((e: any) => {
            if (e instanceof NavigationStart) {
                this._bcOneOff = null;
            }

            if (e instanceof NavigationEnd) {
                this.update();
            }
        });
        this._subscriptions.push(routerSubs);

    }

    ngOnDestroy() {
        this._subscriptions.forEach((sb) => sb.unsubscribe());
    }


    public setBcRoot(value: NpBreadCrumb[] | null) {
        if (value === null) {
            this._bcRoot = [];
        } else {
            this._bcRoot = value
        }
        this.update();
    }

    public setBcApp(value: NpBreadCrumb[] | null) {
        if (value === null) {
            this._bcApp = [];
        } else {
            this._bcApp = value
        }

        this.update();
    }

    public setBcSba(value: NpBreadCrumb[] | null) {
        if (value === null) {
            this._bcSba = [];
        } else {
            this._bcSba = value
        }

        this.update();
    }

    public setBcOneOff(value: NpBreadCrumb | null) {
        this._bcOneOff = value;
        this.update();
    }

    update() {
        let data = this._bcRoot.concat(this._bcApp, this._bcSba);

        if (this._bcOneOff) {
            data.push(this._bcOneOff);
        }
        this._dataBs.next(data);
    }

}